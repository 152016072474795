export const prioritizedCountryKeys = ['DE', 'DK', 'FI', 'NO', 'SE', 'GB', 'US'] as const;

export const countryKeys = [
  'AG',
  'AI',
  'AL',
  'AM',
  'AN',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'COM',
  'CR',
  'CU',
  'CV',
  'CX',
  'CY',
  'CZ',
  'DJ',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'FR_BE',
  'GA',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NL_BE',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'ST',
  'SV',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UK',
  'UM',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
] as const;

export const countryTranslationKeys = {
  DE: 'locale.DE',
  DK: 'locale.DK',
  FI: 'locale.FI',
  NO: 'locale.NO',
  SE: 'locale.SE',
  GB: 'locale.GB',
  US: 'locale.US',
  AG: 'locale.AG',
  AI: 'locale.AI',
  AL: 'locale.AL',
  AM: 'locale.AM',
  AN: 'locale.AN',
  AO: 'locale.AO',
  AQ: 'locale.AQ',
  AR: 'locale.AR',
  AS: 'locale.AS',
  AT: 'locale.AT',
  AU: 'locale.AU',
  AW: 'locale.AW',
  AX: 'locale.AX',
  AZ: 'locale.AZ',
  BA: 'locale.BA',
  BB: 'locale.BB',
  BD: 'locale.BD',
  BE: 'locale.BE',
  BF: 'locale.BF',
  BG: 'locale.BG',
  BH: 'locale.BH',
  BI: 'locale.BI',
  BJ: 'locale.BJ',
  BL: 'locale.BL',
  BM: 'locale.BM',
  BN: 'locale.BN',
  BO: 'locale.BO',
  BR: 'locale.BR',
  BS: 'locale.BS',
  BT: 'locale.BT',
  BV: 'locale.BV',
  BW: 'locale.BW',
  BY: 'locale.BY',
  BZ: 'locale.BZ',
  CA: 'locale.CA',
  CC: 'locale.CC',
  CD: 'locale.CD',
  CF: 'locale.CF',
  CG: 'locale.CG',
  CH: 'locale.CH',
  CI: 'locale.CI',
  CK: 'locale.CK',
  CL: 'locale.CL',
  CM: 'locale.CM',
  CN: 'locale.CN',
  CO: 'locale.CO',
  COM: 'locale.COM',
  CR: 'locale.CR',
  CU: 'locale.CU',
  CV: 'locale.CV',
  CX: 'locale.CX',
  CY: 'locale.CY',
  CZ: 'locale.CZ',
  DJ: 'locale.DJ',
  DM: 'locale.DM',
  DO: 'locale.DO',
  DZ: 'locale.DZ',
  EC: 'locale.EC',
  EE: 'locale.EE',
  EG: 'locale.EG',
  EH: 'locale.EH',
  ER: 'locale.ER',
  ES: 'locale.ES',
  ET: 'locale.ET',
  FJ: 'locale.FJ',
  FK: 'locale.FK',
  FM: 'locale.FM',
  FO: 'locale.FO',
  FR: 'locale.FR',
  FR_BE: 'locale.FR_BE',
  GA: 'locale.GA',
  GD: 'locale.GD',
  GE: 'locale.GE',
  GF: 'locale.GF',
  GG: 'locale.GG',
  GH: 'locale.GH',
  GI: 'locale.GI',
  GL: 'locale.GL',
  GM: 'locale.GM',
  GN: 'locale.GN',
  GP: 'locale.GP',
  GQ: 'locale.GQ',
  GR: 'locale.GR',
  GS: 'locale.GS',
  GT: 'locale.GT',
  GU: 'locale.GU',
  GW: 'locale.GW',
  GY: 'locale.GY',
  HK: 'locale.HK',
  HM: 'locale.HM',
  HN: 'locale.HN',
  HR: 'locale.HR',
  HT: 'locale.HT',
  HU: 'locale.HU',
  ID: 'locale.ID',
  IE: 'locale.IE',
  IL: 'locale.IL',
  IM: 'locale.IM',
  IN: 'locale.IN',
  IO: 'locale.IO',
  IQ: 'locale.IQ',
  IR: 'locale.IR',
  IS: 'locale.IS',
  IT: 'locale.IT',
  JE: 'locale.JE',
  JM: 'locale.JM',
  JO: 'locale.JO',
  JP: 'locale.JP',
  KE: 'locale.KE',
  KG: 'locale.KG',
  KH: 'locale.KH',
  KI: 'locale.KI',
  KM: 'locale.KM',
  KN: 'locale.KN',
  KP: 'locale.KP',
  KR: 'locale.KR',
  KW: 'locale.KW',
  KY: 'locale.KY',
  KZ: 'locale.KZ',
  LA: 'locale.LA',
  LB: 'locale.LB',
  LC: 'locale.LC',
  LI: 'locale.LI',
  LK: 'locale.LK',
  LR: 'locale.LR',
  LS: 'locale.LS',
  LT: 'locale.LT',
  LU: 'locale.LU',
  LV: 'locale.LV',
  LY: 'locale.LY',
  MA: 'locale.MA',
  MC: 'locale.MC',
  MD: 'locale.MD',
  ME: 'locale.ME',
  MF: 'locale.MF',
  MG: 'locale.MG',
  MH: 'locale.MH',
  MK: 'locale.MK',
  ML: 'locale.ML',
  MM: 'locale.MM',
  MN: 'locale.MN',
  MO: 'locale.MO',
  MP: 'locale.MP',
  MQ: 'locale.MQ',
  MR: 'locale.MR',
  MS: 'locale.MS',
  MT: 'locale.MT',
  MU: 'locale.MU',
  MV: 'locale.MV',
  MW: 'locale.MW',
  MX: 'locale.MX',
  MY: 'locale.MY',
  MZ: 'locale.MZ',
  NA: 'locale.NA',
  NC: 'locale.NC',
  NE: 'locale.NE',
  NF: 'locale.NF',
  NG: 'locale.NG',
  NI: 'locale.NI',
  NL: 'locale.NL',
  NL_BE: 'locale.NL_BE',
  NP: 'locale.NP',
  NR: 'locale.NR',
  NU: 'locale.NU',
  NZ: 'locale.NZ',
  OM: 'locale.OM',
  PA: 'locale.PA',
  PE: 'locale.PE',
  PF: 'locale.PF',
  PG: 'locale.PG',
  PH: 'locale.PH',
  PK: 'locale.PK',
  PL: 'locale.PL',
  PM: 'locale.PM',
  PN: 'locale.PN',
  PR: 'locale.PR',
  PS: 'locale.PS',
  PT: 'locale.PT',
  PW: 'locale.PW',
  PY: 'locale.PY',
  QA: 'locale.QA',
  RE: 'locale.RE',
  RO: 'locale.RO',
  RS: 'locale.RS',
  RU: 'locale.RU',
  RW: 'locale.RW',
  SA: 'locale.SA',
  SB: 'locale.SB',
  SC: 'locale.SC',
  SD: 'locale.SD',
  SG: 'locale.SG',
  SH: 'locale.SH',
  SI: 'locale.SI',
  SJ: 'locale.SJ',
  SK: 'locale.SK',
  SL: 'locale.SL',
  SM: 'locale.SM',
  SN: 'locale.SN',
  SO: 'locale.SO',
  SR: 'locale.SR',
  ST: 'locale.ST',
  SV: 'locale.SV',
  SY: 'locale.SY',
  SZ: 'locale.SZ',
  TC: 'locale.TC',
  TD: 'locale.TD',
  TF: 'locale.TF',
  TG: 'locale.TG',
  TH: 'locale.TH',
  TJ: 'locale.TJ',
  TK: 'locale.TK',
  TL: 'locale.TL',
  TM: 'locale.TM',
  TN: 'locale.TN',
  TO: 'locale.TO',
  TR: 'locale.TR',
  TT: 'locale.TT',
  TV: 'locale.TV',
  TW: 'locale.TW',
  TZ: 'locale.TZ',
  UA: 'locale.UA',
  UG: 'locale.UG',
  UK: 'locale.UK',
  UM: 'locale.UM',
  UY: 'locale.UY',
  UZ: 'locale.UZ',
  VA: 'locale.VA',
  VC: 'locale.VC',
  VE: 'locale.VE',
  VG: 'locale.VG',
  VI: 'locale.VI',
  VN: 'locale.VN',
  VU: 'locale.VU',
  WF: 'locale.WF',
  WS: 'locale.WS',
  YE: 'locale.YE',
  YT: 'locale.YT',
  ZA: 'locale.ZA',
  ZM: 'locale.ZM',
  ZW: 'locale.ZW',
} as const;
