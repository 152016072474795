import styled from 'styled-components';
import { SrOnly } from '../SrOnly';

const Wrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled.select`
  -webkit-appearance: none;
  appearance: none;

  height: 56px;
  background-color: var(--form-field-bg);
  border: 1px solid var(--form-field-border-color);
  border-radius: 0;
  color: var(--text-color);
  display: inline-block;
  font-family: var(--body-font-family);
  font-size: 0.9375rem;

  line-height: 1.5;
  margin: 0;
  max-width: 100%;
  outline: 0;
  padding: var(--form-field-padding-y) var(--form-field-padding-x);
  text-align: left;
  text-decoration: none;
  text-transform: none;
  width: 100%;

  transition: all 0.25s 0s var(--easing);

  &:focus {
    border-color: var(--form-field-focus-border-color);
    background-color: var(--form-field-focus-bg);
  }

  option {
    padding: 0;
  }

  appearance: none;
  background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: calc(100% - 1.25rem);
`;

type Props = {
  name: string;
  label: string;
  options: string[][];
  defaultValue?: string;
  required?: boolean;
};

const Select = ({ name, label, options, defaultValue, required }: Props) => (
  <Wrapper>
    <SrOnly as="label" htmlFor="select-country">
      {label}
    </SrOnly>
    <StyledSelect name={name} defaultValue={defaultValue} required={required} id="select-country">
      <option value="" disabled hidden>
        {label}
      </option>
      {options.map(([key, value]) => (
        <option key={key} value={key} disabled={value === '---'}>
          {value}
        </option>
      ))}
    </StyledSelect>
  </Wrapper>
);

export default Select;
