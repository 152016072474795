import styled from 'styled-components';

const Banner = styled.div<{ $background: string; $color?: string }>`
  position: absolute;
  top: -1.5rem;
  left: -1rem;
  z-index: 1;
  padding: 0.5rem 1.5rem;
  background: ${({ $background }) => $background || 'var(--color-purple)'};
  color: ${({ $color }) => $color || 'var(--color-white)'};
  width: calc(95%);
  @media (min-width: 768px) {
    width: calc(80%);
  }
  font-size: 1.5rem;
  font-weight: bold;

  &:after {
    position: absolute;
    background: inherit;
    left: 0;
    top: 52px;
    content: '';
    filter: brightness(0.25);
    height: 50px;
    width: 50px;
    clip-path: polygon(0 0, 32% 40%, 32% 0);
  }
`;

const BannerText = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(90%);
`;

const getBackground = (background: string) => {
  if (background !== 'purple' && background !== 'deep-purple') {
    return 'var(--color-purple)';
  } else {
    return 'var(--color-primary)';
  }
};

const FormTitleBanner = ({
  title,
  background,
  color,
}: {
  title: string;
  background: string | null;
  color?: string;
}) => {
  return (
    <Banner
      $background={!background ? 'var(--color-purple)' : getBackground(background)}
      $color="var(--color-white)"
    >
      <BannerText>{title}</BannerText>
    </Banner>
  );
};

export default FormTitleBanner;
