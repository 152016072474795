import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    height: 1.2rem;
    margin: 0 0.35rem 0 0;
    align-self: flex-start;
    accent-color: var(--color-primary, auto);
  }

  label {
    display: flex;
    font-size: var(--font-size-small);
    color: var(--text-color);
    letter-spacing: var(--body-letter-spacing, 0);
    line-height: var(--body-line-height, 1.6);
  }
`;

const Checkbox = ({
  label,
  name,
  required,
}: {
  label: string | React.ReactNode;
  name: string;
  required?: boolean;
}) => {
  return (
    <Wrapper>
      <label>
        <input type="checkbox" name={name} required={required} />
        {label}
      </label>
    </Wrapper>
  );
};

export default Checkbox;
