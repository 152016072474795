import styled from 'styled-components';
import { ButtonVariants, getButtonStyling } from './styling';

const StyledButton = styled.button<{
  $variant: ButtonVariants;
  $fullWidth?: boolean;
}>`
  ${({ $variant, $fullWidth }) => getButtonStyling({ $variant, $fullWidth })}
`;

type Props = {
  children: React.ReactNode;
  variant: ButtonVariants;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  onClick?: () => void;
};

const Button = ({ children, variant, fullWidth, ...rest }: Props) => {
  return (
    <StyledButton $variant={variant} $fullWidth={fullWidth} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
