import { SignupPageTypes } from '../constants';
import { FormSubmissionEvent } from '../lib/tracker';

export const getTrackingEventByType = (
  type?: 'monitor-reports' | 'product-tour',
): FormSubmissionEvent['event'] => {
  switch (type) {
    case SignupPageTypes.MONITOR_REPORTS:
      return 'M&R Trial Signup - Form Submitted';
    case SignupPageTypes.PRODUCT_TOUR:
      return 'Product Tour Trial Signup - Form Submitted';
    default:
      return 'Trial Signup - Form Submitted';
  }
};
