import { useEffect } from 'react';
import { PASSWORD_MIN_LENGTH } from '../constants';

const useFormPasswordValidation = () => {
  useEffect(() => {
    const handlePasswordValidation = (input: HTMLInputElement) => {
      input.addEventListener('input', () => {
        const value = input.value;

        if (value.length < PASSWORD_MIN_LENGTH || value.length === 0) {
          input.setCustomValidity('');
          return;
        }

        const hasLowerCase = value !== value.toUpperCase();
        const hasUpperCase = value !== value.toLowerCase();
        const hasNumber = /\d/.test(value);

        if (!hasLowerCase || !hasUpperCase || !hasNumber) {
          input.setCustomValidity(
            'The password must contain at least one lowercase letter, one uppercase letter and one digit.',
          );
        } else {
          input.setCustomValidity('');
        }
      });
    };

    const inputs = document.querySelectorAll<HTMLInputElement>('[data-password-validation]');
    inputs.forEach(input => {
      handlePasswordValidation(input);
    });

    return () => {
      inputs.forEach(input => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        input.removeEventListener('input', handlePasswordValidation);
      });
    };
  }, []);
};

export default useFormPasswordValidation;
