export function getCookieByName(name: string): string | null {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(nameEQ)) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }
  return null;
}

export function getAllCookies(): Record<string, string> {
  const cookies: Record<string, string> = {};
  const cookieString = document.cookie;

  if (!cookieString) {
    return cookies;
  }

  const cookieArray = cookieString.split(';');

  cookieArray.forEach(cookie => {
    const [name, ...rest] = cookie.trim().split('=');
    const value = rest.join('=');
    cookies[name] = decodeURIComponent(value);
  });

  return cookies;
}
